.profil .corps-page .profil-centre .profil-titre-email {
  font-size: 34px;
  color: var(--bleu-principal);
  text-align: center;
  margin: 30px 0;
}

.profil .corps-page .profil-centre .titre-section {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.profil .corps-page .profil-centre .titre-section p {
  grid-column: 1 / 2;
  color: var(--bleu-principal);
  font-size: 18px;
  margin: 0 10px 0 0;
}

.profil .corps-page .profil-centre .titre-section .separateur {
  grid-column: 2 / 3;
  height: 1px;
  background-color: var(--gris-separateur-fin);
  border-radius: 2px;
  margin-top: 5px;
}

.profil .corps-page .profil-centre .titre-section .action {
  grid-column: 3 / 4;
  margin-left: 10px;
}

.profil .corps-page .profil-centre .titre-section .action button {
  border: none;
  color: #000000;
  font-size: 12px;
  background-color: #ffffff;
}

.profil .corps-page .profil-centre .titre-section .action button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.profil .corps-page .profil-centre .formulaire-infos,
.profil .corps-page .profil-centre .formulaire-infos .champs-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.profil .corps-page .profil-centre form {
  width: 100%;
}

.profil .corps-page .profil-centre .formulaire-infos .champ {
  display: grid;
  grid-template-columns: 40% 55%;
  width: 450px;
  column-gap: 5%;
  align-items: center;
  margin: 3% 0;
}

.profil .corps-page .profil-centre .formulaire-infos .champ .label {
  color: var(--bleu-principal);
  font-size: 18px;
  text-align: right;
}

.profil .corps-page .profil-centre .formulaire-infos .champ .valeur {
  padding: 0 0 4px 0;
  border-bottom: 1px solid black;
  height: 2em;
}

.profil .corps-page .profil-centre .formulaire-infos .boutons {
  display: flex;
  flex-direction: row-reverse;
}

.profil .corps-page .profil-centre .formulaire-infos .boutons button {
  max-width: 220px;
  padding: 3px 14px;
  border-radius: 29px;
  margin: 10px;
  color: #ffffff;
  font-size: 1.2rem;
}

.profil .corps-page .profil-centre .formulaire-infos .boutons button:hover {
  cursor: pointer;
}

.profil .corps-page .profil-centre .formulaire-infos .boutons button.annuler {
  background: var(--gris-separateur);
  border: 1px solid var(--gris-separateur);
}

.profil .corps-page .profil-centre .formulaire-infos .boutons button.valider {
  background: var(--bleu-principal);
  border: 1px solid var(--bleu-principal);
}

@media all and (max-width: 692px) {
  .profil .corps-page .profil-centre .formulaire-infos .champ {
    width: 100%;
  }

  .profil .corps-page .profil-centre .profil-titre-email {
    font-size: 1em;
    margin: 1em 0;
  }
}
