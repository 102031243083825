.ajout .arborescence img {
  height: 18px;
}

.ajout .arborescence ul {
  padding-left: 12px;
}

.ajout .arborescence ul li::before {
  top: 18px;
}

.ajout .arborescence ul li:last-child::after {
  height: 18px;
}

.ajout .arborescence ul li.dossier {
  color: var(--bleu-principal);
}

.ajout .arborescence ul li.document {
  color: #000000;
}

.ajout .groupe label:hover {
  cursor: pointer;
}

.ajout .deux-colonnes table td {
  padding: 0px;
}
