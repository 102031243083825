.erreur {
  color: var(--erreur-police);
}

.message-erreur {
  background-color: #fff3f3;
  color: #404d53;
  width: 100%;
  padding: 10px;
  border: #d2aeae 1px solid;
  border-radius: 10px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.message-erreur h1 {
  font-size: 1.1rem;
  margin-top: 0.1%;
  margin-bottom: 0.1%;
}

.message-carte .message.erreur {
  background-color: #f0f0f0;
  color: var(--erreur-police);
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 10px;
  grid-template-rows: auto auto;
  border: 1px solid var(--gris-separateur);
}

.message-carte .message.erreur h1 {
  color: #b32926;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
}
