.page {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  overflow: auto;
}

.page .bandeau-lateral-gauche {
  background-color: var(--bleu-principal);
  padding: 20px;
  width: 225px;
}

.page .bandeau-lateral-gauche a {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
}

.page .bandeau-lateral-gauche a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: #bfc0e3;
}

.page .bandeau-lateral-gauche .active a {
  text-decoration: underline;
  text-underline-offset: 5px;
  font-weight: bold;
}

.page .corps-page {
  grid-column: 2/3;
  display: grid;
}

.page.profil .corps-page {
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-items: center;
}

.page.assistance .corps-page {
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
}

.page .corps-page .page-centre {
  width: 100%;
  grid-row: 2/3;
}

.page.assistance {
  color: #181818;
}

.page.assistance .corps-page h2 {
  font-size: 1.2rem;
  color: #292929;
  font-weight: normal;
}

.page.assistance .assistance-contact img.assistance-picto {
  width: 24px;
  margin-right: 10px;
}

.page.assistance .assistance-contact {
  border-left: 1px solid #d8d8d8;
  padding: 50px;
  max-width: 100%;
  width: 485px;
  max-width: 100%;
  align-self: start;
}

.page.assistance .assistance-contact textarea {
  width: 100%;
  height: 250px;
  border: 1px solid #979797;
  border-radius: 20px 20px 0 20px;
  padding: 20px;
}

.page.assistance .assistance-contact .boutons {
  padding: 15px 0;
  display: flex;
  flex-direction: row-reverse;
}

.page.assistance .support-message-confirmation {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 20px auto;
}

.page.assistance .support-message-confirmation p {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  align-self: center;
  justify-self: left;
  background-color: #e7f1ce;
  color: #181818;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  margin: 0;
}

.page.assistance .support-message-confirmation span {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  align-self: start;
  justify-self: right;
  margin: 2px 11px 0 0;
}

.page.assistance .support-message-confirmation span:hover {
  cursor: pointer;
}

.page.assistance .assistance-aide,
.page.assistance .assistance-lien-aide {
  padding: 1em;
}

.page.assistance .liste-quest-assistance {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
}

.page.assistance .liste-quest-assistance .elem-quest-assistance {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: left;
  column-gap: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 300px;
  color: var(--bleu-principal);
}

.page.assistance .liste-quest-assistance .elem-quest-assistance:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.capture-aide {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  max-width: 100%;
  width: 800px;
}

.page.assistance .assistance-aide .souris {
  width: 40px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.page.assistance .assistance-aide img {
  margin: 8px;
}

.page.assistance .assistance-aide ul li {
  list-style: inside;
}

@media all and (max-width: 692px) {
  .bandeau-lateral-gauche {
    display: none;
  }
}

@media all and (min-width: 692px) {
  .page .titre {
    display: none;
  }
}

@media all and (max-width: 1100px) {
  .page.assistance .corps-page {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    overflow-y: auto;
  }

  .page.assistance .assistance-contact {
    border-left: none;
  }

  .page.assistance .assistance-contact {
    padding: 1.5em;
  }
}
