@font-face {
  font-family: "Lato Regular";
  src: url("../../medias/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Light";
  src: url("../../medias/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Thin";
  src: url("../../medias/fonts/Lato/Lato-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Lufga Regular";
  src: url("../../medias/fonts/Lufga/Lufga-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Lufga Light";
  src: url("../../medias/fonts/Lufga/Lufga-Light.otf") format("opentype");
}

@font-face {
  font-family: "Lufga Extra Light";
  src: url("../../medias/fonts/Lufga/Lufga-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "Lufga Thin";
  src: url("../../medias/fonts/Lufga/Lufga-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Lufga Bold";
  src: url("../../medias/fonts/Lufga/Lufga-Bold.otf") format("opentype");
}
