.quatre-cent-quatre {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  width: 100vw;
  color: var(--bleu-principal);
}

.quatre-cent-quatre .haut {
  align-self: end;
}

.quatre-cent-quatre .oups-gros {
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  flex-wrap: wrap;
}

.quatre-cent-quatre .oups {
  font-size: 1.5em;
  justify-self: right;
  padding-top: 2em;
}

.quatre-cent-quatre .gros {
  font-size: 9em;
  justify-self: left;
}

.quatre-cent-quatre .liens {
  width: 100%;
  background-color: var(--bleu-principal);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 5%;
}

.quatre-cent-quatre .liens .courbes {
  grid-column: 1/3;
  grid-row: 1/2;
}

.quatre-cent-quatre .liens .courbes {
  display: flex;
  flex-direction: column;
  background-color: #7a8efd;
  width: 100%;
  height: 30px;
  border-radius: 50%/0 0 30px 30px;
}

.quatre-cent-quatre .liens .courbes .courbe-bleue {
  display: flex;
  flex-direction: column;
  background-color: #c6cfff;
  width: 100%;
  height: 20px;
  border-radius: 50%/0 0 20px 20px;
}

.quatre-cent-quatre .liens .courbes .courbe-blanche {
  background-color: white;
  width: 100%;
  height: 10px;
  border-radius: 50%/0 0 10px 10px;
}

.quatre-cent-quatre .liens .colonne.gauche {
  grid-column: 1/2;
  grid-row: 2/3;
  justify-self: right;
}

.quatre-cent-quatre .liens .colonne.droite {
  grid-column: 2/3;
  grid-row: 2/3;
  justify-self: left;
}

.quatre-cent-quatre .liens .colonne > div {
  margin: 2em 0;
}

.quatre-cent-quatre .liens a {
  color: #ffffff;
  text-decoration: none;
}

.quatre-cent-quatre .liens a:hover {
  text-decoration: underline;
}
