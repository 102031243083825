.notifications .corps-page {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr;
}

.notifications .corps-page .choix-ecran {
  background-color: var(--bleu-tertiaire);
}

.notifications .corps-page .choix-ecran div {
  padding: 10px;
}

.notifications .corps-page .choix-ecran div.separateur {
  margin: 20px auto;
  padding: 0;
  height: 2px;
  width: 80%;
  background-color: #919191;
}

.notifications .corps-page .choix-ecran div span {
  color: #181818;
  font-family: "Lato regular";
}

.notifications .corps-page .choix-ecran div span.active {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.notifications .corps-page .choix-ecran div span:hover {
  cursor: pointer;
}

.notifications .corps-page .liste-notifs {
  max-width: 800px;
  padding-top: 9px;
}

.notifications .corps-page .notification {
  display: grid;
  grid-template-columns: 170px 1fr 50px;
  grid-template-rows: auto 50px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border: 1px solid rgba(48, 73, 215, 0.24);
  overflow: hidden;
  margin-top: -9px;
  background: #fff;
}

.notifications .corps-page .notification.ferme {
  height: 100px;
}

.notifications .corps-page .notification .date {
  grid-column: 1/2;
  grid-row: 1/3;
  padding: 1em;
}

.notifications .corps-page .notification .date .date-jour {
  font-size: 1.5em;
}

.notifications .corps-page .notification.ferme .date .date-jour {
  font-size: 1.2em;
}

.notifications .corps-page .notification .date.non-lue {
  background-color: #7a8efd;
}

.notifications .corps-page .notification .date.lue {
  background-color: #eef0ff;
}

.notifications .corps-page .notification .corps-notif {
  grid-column: 2/3;
  grid-row: 1/2;
  background: #fff;
  overflow: hidden;
  padding-left: 10px;
}

.notifications .corps-page .notification .bt-droit {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: center;
  margin-top: 15px;
}

.notifications .corps-page .notification .bt-droit .bt-ferme {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: 15px;
  height: 15px;
}

.notifications .corps-page .notification .bt-droit .bt-ferme div {
  width: 15px;
  height: 2px;
  background-color: #181818;
}

.notifications .corps-page .notification .bt-droit .bt-ouvert {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  width: 10px;
  height: 15px;
}

.notifications .corps-page .notification .bt-droit .bt-ouvert div {
  width: 2px;
  height: 15px;
  background-color: #181818;
}

.notifications .corps-page .notification .bt-bas {
  grid-column: 3/4;
  grid-row: 2/3;
  justify-self: center;
  align-self: center;
  margin-bottom: 6px;
}

.notifications .corps-page .notification .bt-bas button {
  border: none;
  background: none;
}

.notifications .corps-page .envoi {
  padding: 10px;
}

.notifications .corps-page .envoi.confirmation div {
  margin: 10px 0;
}

.notifications .corps-page .envoi form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto;
}

.notifications .corps-page .envoi form > div {
  margin-top: 15px;
  margin-bottom: 15px;
}

.notifications .corps-page .envoi form > div p {
  margin: 0;
}

.notifications .corps-page .envoi form .notif-liste-grp {
  padding-left: 10px;
}

.notifications .corps-page .envoi form .notif-liste-grp label:hover {
  cursor: pointer;
}

.notifications .corps-page .envoi form input[type="text"] {
  width: 500px;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 5px 20px;
}

.notifications .corps-page .envoi form textarea {
  width: 500px;
  height: 250px;
  border: 1px solid #979797;
  border-radius: 20px 20px 0 20px;
  padding: 20px;
}

.notifications .corps-page .envoi form .bt-envoi-notif {
  display: flex;
  gap: 10px;
}
