.reset-some-element {
  all: initial;
  * {
    all: unset;
  }
}

.message {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 10px;
  grid-template-rows: auto auto;
}

.message h1 {
  margin-top: 0;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.message button {
  background: transparent;
  border: none;
  width: 10px;
  height: 10px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.message button:hover {
  cursor: pointer;
}

.message div {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.message.erreur {
  background-color: var(--erreur-fond);
  color: var(--erreur-police);
  box-shadow: 0 0 0 1px var(--erreur-contour) inset, 0 0 0 0 transparent;
}

.message.alerte {
  background-color: var(--alerte-fond);
  color: var(--alerte-police);
  box-shadow: 0 0 0 1px var(--alerte-contour) inset, 0 0 0 0 transparent;
}

.message.succes {
  background-color: var(--succes-fond);
  color: var(--succes-police);
  box-shadow: 0 0 0 1px var(--succes-contour) inset, 0 0 0 0 transparent;
}
