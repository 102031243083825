input.rond {
  padding: 13px;
  border-radius: 29px;
}

input.gris {
  border: 2px solid #eee;
  background: #eee;
}

input:focus.gris {
  border: 2px solid rgb(190, 190, 190);
  outline: none;
}

.champ.lecture-seule {
  opacity: 0.45;
}
