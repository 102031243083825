.selection {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  overflow: auto;
  padding: 2%;
}

.selection h1 {
  grid-row: 1 / 2;
  width: 100%;
  max-width: 1024px;
  font-family: "Lato Regular";
  font-size: 1.5rem;
  color: #292929;
  font-weight: normal;
}

.selection h1 span.bleu {
  color: var(--bleu-principal);
}
