.carte {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr auto;
  overflow: hidden;
  grid-row: 2/3;
}

.carte .zone-travail {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
}

.carte .message-carte {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  align-self: flex-start;
  justify-self: center;
  z-index: 10000;
}

.carte .selecteurs-panneaux {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  justify-self: right;
  align-self: flex-start;
  width: 75px;
  z-index: 2600;
  background-color: #707070;
}

.carte .selecteurs-panneaux .separateur-panneaux,
.carte .panneaux .separateur-panneaux {
  height: 10px;
  background-color: #eef0ff;
  margin-bottom: 1px;
}

.carte .infos-tache .separateur-panneaux,
.carte .barre-outils .separateur-panneaux {
  height: 10px;
  background-color: #eef0ff;
}

.carte .selecteurs-panneaux .selecteur {
  width: 75px;
  height: 75px;
}

.carte .selecteurs-panneaux .selecteur button {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 0;
  border: transparent;
  margin: 1px auto;
  letter-spacing: 1.2px;
  font-family: "Lato Regular";
}

.carte .selecteurs-panneaux .selecteur.actif button {
  background-color: #3049d7;
  color: #ffffff;
}

.carte .panneaux {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  background-color: #f0f0f0;
  width: clamp(250px, 30vw, 450px);
  overflow-y: auto;
  z-index: 2800;
}

.carte .panneaux .infos {
  margin: 40px;
}

.carte .panneaux .infos form {
  width: 380px;
  max-width: 100%;
}

.carte .panneaux .infos .champ .label {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: 20px 0;
}

.carte .panneaux .infos .champ .entete {
  display: grid;
  grid-template-columns: auto auto minmax(0px, 1fr) auto;
  align-items: center;
}

.carte .panneaux .infos .champ .entete p {
  font-family: "Lato Regular";
  font-size: 18px;
}

.carte .panneaux .infos .champ .entete .trait {
  grid-column: 3 / 4;
  display: grid;
  grid-template-columns: minmax(0px, 10px) 1fr;
}

.carte .panneaux .infos .champ .entete .trait .espace {
  grid-column: 1 / 2;
}

.carte .panneaux .infos .champ .entete .trait .plein {
  grid-column: 2 / 3;
  height: 1px;
  background-color: gray;
  border-radius: 2px;
  margin-top: 5px;
}

.carte .panneaux .infos .champ .entete .edit {
  margin-left: 10px;
  grid-column: 4 / 5;
}

.carte .panneaux .infos .champ .lecture {
  font-family: "Lato Light";
  font-size: 14px;
}

.carte .panneaux .infos .champ .label .text {
  grid-column: 1 / 2;
  font-size: 1.2em;
}

.carte .panneaux .infos .champ .label .decor {
  grid-column: 2 / 3;
  width: 100%;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 10px;
  height: 2px;
  background-color: gray;
  border-radius: 2px;
}

.carte .panneaux .infos form .champ .element input[type="text"],
.carte .panneaux .infos form .champ .element textarea {
  width: 100%;
  border-radius: 3px;
  border-color: gray;
  border-width: 1px;
  font-size: 1.1em;
  font-weight: normal;
}

.carte .panneaux .infos form .champ .element textarea {
  height: 200px;
  resize: none;
}

.carte .panneaux .infos form .champ .element select {
  display: none;
}

.filtre-idees .element.select,
.carte .panneaux .infos .champ .element.select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filtre-idees .element.select .select-elem,
.carte .panneaux .infos .champ .element.select .select-elem {
  display: grid;
  margin: 8px;
  align-items: center;
  justify-items: center;
  border: solid 1px var(--gris-separateur);
  border-radius: 5px;
}

.filtre-idees .element.select .select-elem.selected,
.carte .panneaux .infos .champ .element.select .select-elem.selected {
  border-color: #808ae5;
  color: #808ae5;
}

.filtre-idees .element.select .select-elem p,
.carte .panneaux .infos .champ .element.select .select-elem p {
  width: 150px;
  padding: 8px;
  margin: 0;
}

.filtre-idees .element.select .select-elem:hover,
.carte .panneaux .infos .champ .element.select .select-elem:hover {
  cursor: pointer;
  background-color: #e4e6f5;
}

.carte
  .panneaux
  .infos
  .champ.lecture-seule
  .element.select
  .select-elem:hover {
  cursor: default;
  background-color: transparent;
}

.carte .panneaux .infos .champ .element.groupe-radio > div {
  display: grid;
  grid-template-columns: auto auto;
  margin: 5px 0;
}

.carte .panneaux .infos .champ .element.groupe-radio > div label {
  grid-column: 1 / 2;
  justify-self: left;
}

.carte .panneaux .infos .champ .element.groupe-radio > div input[type="radio"] {
  grid-column: 2 / 3;
  justify-self: right;
}

.carte .panneaux .infos form .champ .element.checkbox {
  text-align: right;
}

.leaflet-container {
  height: 100%;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

.carte .panneaux {
  letter-spacing: 0.1rem;
}

.carte .panneaux .entete-panneau {
  border-bottom: 1px solid var(--gris-separateur);
  display: grid;
  grid-template-columns: 1fr auto;
}

.carte .panneaux .entete-panneau p {
  text-align: center;
  font-size: 1.1rem;
}

.carte .panneaux .entete-panneau button {
  align-self: center;
  justify-self: center;
}

.carte .selecteurs-carte .selecteur-carte {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-bottom: 2px solid var(--gris-separateur);
}

.carte .selecteurs-carte .selecteur-carte:hover {
  cursor: pointer;
  background-color: var(--active);
}

.carte .selecteurs-carte .selecteur-carte.active {
  background-color: var(--active);
}

.carte .panneaux .selecteurs-carte .selecteur-carte .type-carte {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin: 10px;
}

.carte .panneaux .selecteurs-carte .selecteur-carte p {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  margin: 10px;
}

.carte .panneaux .selecteurs-carte .selecteur-carte .oeil {
  width: 23px;
  margin: 0 6px 0 9px;
}

.ici-icone-circle {
  border-radius: 18px 18px 18px 18px;
  box-shadow: 6px 13px 8px -4px black;
  width: 36px;
  height: 36px;
  line-height: 36px;
  opacity: 0.9;
  text-align: center;
  padding: 10px 0 0 10px;
}

.leaflet-marker-draggable .ici-icone-circle {
  box-shadow: 0 0 1px 2px #000, 0px 0 5px 4px #f0f;
}

.leaflet-marker-draggable .ici-icone-triangle {
  box-shadow: 0 0 1px 2px #000, 0px 0 5px 4px #f0f;
}

.carte .legende .element {
  display: grid;
  grid-template-columns: auto 100px 1fr;
  grid-template-rows: auto;
  align-items: center;
}

.carte .legende .element.decorateur {
  margin-left: 30px;
  grid-template-columns: auto 30px 70px 1fr;
  background-color: rgba(196, 196, 196, 0.5);
  font-size: 0.8em;
}

.carte .legende .element.decorateur > span {
  grid-column: 1 / 2;
  margin-left: 3px;
}

.carte .legende .element img {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 23px;
  justify-self: center;
  margin: 0 6px 0 9px;
}

.carte .legende .element.decorateur img {
  grid-column: 2 / 2;
}

.carte .legende .element img:hover {
  cursor: pointer;
}

.carte .legende .element .picto-legende {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  text-align: center;
}

.carte .legende .element.decorateur .picto-legende {
  grid-column: 3 / 4;
}

.carte .legende .element.decorateur .picto-legende {
  grid-column: 3 / 4;
}

.carte .legende .element.decorateur .picto-legende svg {
  width: 45px;
}

.carte .legende .element .titre {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.carte .legende .element .titre.sans-picto {
  grid-column: 2 / 4;
}

.carte .legende .element.decorateur .titre {
  grid-column: 4 / 5;
  font-style: italic;
}

.carte .panneaux .infos .boutons {
  margin: 20px 0;
  display: flex;
  flex-direction: row-reverse;
}

.carte .panneaux .infos .boutons button {
  font-size: 13px;
  padding: 10px 18px;
  letter-spacing: 5px;
  border-radius: 30px;
}

.carte .panneaux .infos .boutons button:hover {
  cursor: pointer;
}

.carte .panneaux .infos .boutons .valider {
  color: white;
  background-color: var(--bleu-principal);
  border: none;
  margin-left: 10px;
}

.carte .panneaux .infos .boutons .valider:hover {
  background-color: var(--bleu-secondaire);
}

.carte .panneaux .infos .boutons .annuler {
  border: 1px solid black;
}

.carte .panneaux .infos .boutons .annuler:hover {
  background-color: var(--gris-hover-bouton);
}

.carte .panneaux .infos .carousel {
  display: grid;
  grid-template-columns: 1fr 290px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  column-gap: 10px;
}

.carte .panneaux .infos .carousel .navigation {
  font-size: 30px;
  border: none;
}

.carte .panneaux .infos .carousel .navigation:hover {
  cursor: pointer;
}

.carte .panneaux .infos .carousel .precedent {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: right;
}

.carte .panneaux .infos .carousel .suivant {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: left;
}

.carte .panneaux .infos .carousel .corps {
  grid-column: 2/3;
  grid-row: 1/2;
  background-color: var(--gris-hover-bouton);
  padding: 10px;
  border-radius: 10px;
}

.carte .panneaux .infos .carousel .corps .image {
  width: 270px;
  height: 200px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-content: center;
  justify-content: center;
  background: #888;
}

.carte .panneaux .infos .carousel .corps .image img {
  max-width: 270px;
  max-height: 200px;
}

.carte .panneaux .infos .carousel .corps .titre {
  display: grid;
  grid-template-columns: auto 1fr;
  color: white;
}

.carte .panneaux .infos .carousel .corps .titre .compteur {
  grid-column: 1/2;
  justify-self: left;
}

.carte .panneaux .infos .carousel .corps .titre .nom {
  grid-column: 2/3;
  justify-self: right;
}

.carte .panneaux .infos .champ .existantes .liste {
  display: flex;
}

.carte .panneaux .infos .champ .existantes .liste .existant {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  align-items: center;
  padding: 7px;
  border-radius: 7px;
  background-color: var(--gris-hover-bouton);
  border: solid 1px var(--gris-hover-bouton);
  margin: 5px;
  cursor: pointer;
}

.carte .panneaux .infos .champ .existantes .liste .existant .apercu {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 80px;
  height: 55px;
}

.carte .panneaux .infos .champ .existantes .liste .existant.suppression {
  border-color: brown;
}

.carte .panneaux .infos .champ .existantes .liste .existant img {
  width: 100%;
}

.carte .panneaux .infos .champ .ajout > p {
  margin: 4px auto;
}

.carte .panneaux .infos .champ .ajout > div {
  margin: 4px auto;
}

.carte .panneaux .infos .supprimer {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
  gap: 10px;
}

.carte .panneaux .infos .supprimer button {
  color: white;
  background-color: var(--bleu-principal);
  border: none;
  margin-left: 10px;
  font-size: 13px;
  padding: 10px 18px;
  letter-spacing: 5px;
  border-radius: 30px;
}

.carte .panneaux .infos .supprimer button:hover {
  cursor: pointer;
}

.aide-question {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 1px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  font: 12px Arial, sans-serif;
  margin: 0 5px;
}

.texte-aide-question {
  width: 200px;
  position: fixed;
  background: white;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.aide-question:hover {
  cursor: pointer;
}

.couverture-carte {
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 2750;
  grid-column: 1 / 4;
  grid-row: 1 / 3;
}

.couverture-infos {
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 2750;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.separateur-boutons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.geometrie-active {
  filter: url(#neon);
}

.optionnel {
  font-size: 0.8em;
  font-style: italic;
  color: #777777;
}

.contribution {
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-template-rows: 1fr;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
}

.contribution:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.contribution .texte-contrib {
  padding-left: 10px;
}

.contribution .nom-contrib {
  font-size: 16px;
}

.contribution .date-contrib {
  font-size: 12px;
  color: #979797;
}

.contribution p {
  margin: 0;
}

.contribution .bt-profil {
  align-self: center;
  justify-self: center;
  height: 50px;
  width: 50px;
}

.retour-contributions,
.nouvelle-contribution {
  margin: 10px 0;
}

.retour-contributions button,
.nouvelle-contribution button {
  font-size: 18px;
  border-color: transparent;
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: left;
}

.nouvelle-contribution button span.contrib-plus {
  font-size: 54px;
  justify-self: center;
  padding-bottom: 5px;
}

.retour-contributions button span.fleche-contrib {
  font-size: 35px;
  justify-self: center;
  padding-bottom: 7px;
}

@media all and (max-width: 512px) {
  .carte .selecteurs-panneaux {
    width: 60px;
  }
  .carte .selecteurs-panneaux button {
    font-size: 11px;
  }
  .carte .selecteurs-panneaux button img {
    width: 35px;
    max-height: 35px;
  }
  .carte .panneaux .infos .champ .entete p {
    font-size: 12px;
  }
  .carte .panneaux .infos {
    margin: 15px;
  }
  .carte .panneaux .infos .champ .lecture {
    font-size: 11px;
  }
  .carte .legende .element .titre {
    font-size: 11px;
  }
  .carte .legende .element .picto-legende svg {
    width: 70px;
  }
}
