.fichiers .deplacement {
  overflow-y: scroll;
}

.fichiers .deplacement h2 {
  font-size: 18px;
}

.fichiers .deplacement .boutons {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  button {
  border: none;
  background: none;
  font-size: 20px;
  color: black;
  margin: 0;
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  .dossier {
  color: var(--bleu-principal);
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  .selectionne {
  font-weight: bold;
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  .selectionner {
  margin: 0 10px 0 0;
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  button
  img {
  margin-left: 10px;
}

.fichiers
  .corps
  .liste-fichiers
  .lignes
  .ligne
  .actions
  .deplacement
  ul
  button
  span {
  width: 15px;
  text-align: center;
  display: inline-block;
}

.fichiers .deplacement span.ouvrir {
  color: black;
  font-weight: bold;
  font-size: 1em;
}

.fichiers .deplacement span.ouvrir:hover {
  cursor: pointer;
}
