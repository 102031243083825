/* déprécié */
.toastmessages {
  position: fixed;
  top: 15px;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, 0);
}

.couverture {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 8000;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
}

.couverture .popup {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  transition: all 5s ease-in-out;
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: auto;
}

@media (max-width: 400px) {
  .couverture .popup {
    padding: 5%;
  }
}
