.barre-outils {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  align-self: flex-start;
  justify-self: left;
  width: 75px;
  z-index: 2500;
  background-color: #707070;
  border: none;
  display: flex;
  gap: 1px;
  flex-direction: column;
}

.barre-outils .outil button {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border: none;
  letter-spacing: 1.2px;
  font-family: "Lato Regular";
}

.barre-outils .outil > div button {
  border-top: 1px solid #707070;
  padding: 5px;
  margin-top: -1px;
  grid-template-rows: 1fr;
}

.barre-outils .outil > div button:hover {
  background-color: #ffffff;
}

.barre-outils .outil button.active {
  background-color: #3049d7;
  color: #ffffff;
}

.outil {
  position: relative;
  height: 75px;
  width: 75px;
}

.couche-outil.hidden {
  display: none;
}

.couche-outil.displayed {
  left: 75px;
  position: absolute;
  width: 200px;
  top: 0;
}

.barre-outils .outil button:disabled {
  cursor: default;
}

@media all and (max-width: 512px) {
  .barre-outils {
    width: 60px;
  }

  .barre-outils button {
    font-size: 11px;
  }

  .barre-outils button img {
    width: 35px;
    max-height: 35px;
  }

  .couche-outil.displayed {
    left: 60px;
  }
}
