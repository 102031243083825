@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.bulle-aide {
  position: relative;
  width: 100%;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.8s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.barre-action .contexte .bulle-aide {
  width: 20%;
}
.barre-action .zoom .bulle-aide-click {
  bottom: -19px;
  right: 10px;
}
.selecteurs-panneaux .bulle-aide-click {
  bottom: 9px;
  right: 50px;
}
.bulle-aide:hover {
  cursor: pointer;
  animation: none;
}
.bulle-aide.bulle-active {
  animation: none;
}
.bulle-aide-click {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fafd99;
  text-align: center;
  font-size: 25px;
  color: #979797;
  border: #979797 1px solid;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.barre-action .bulle-aide {
  position: relative;
  width: 100%;
  z-index: 5000;
}
.barre-action .bulle-aide-click {
  bottom: 3px;
  right: -20px;
}

.barre-action .contexte .bulle-aide-click {
  bottom: 3px;
  right: 20px;
}

.outil .bulle-aide {
  position: relative;
  width: 100%;
}
.outil .bulle-aide-click {
  bottom: 3px;
  right: -20px;
}
.contenu-bulle-aide {
  position: fixed;
  width: 300px;
  background-color: #fafd99;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 12px;
  z-index: 5000;
  font-size: 15px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 19px 38px,
    rgba(0, 0, 0, 0.44) 0px 15px 12px;
}
.contenu-bulle-aide img {
  margin: 8px;
}
.contexte .contenu-bulle-aide {
  width: 500px;
}
.contenu-bulle-aide ul li {
  list-style: inside;
}
