.inscription {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-family: "Lato Light";
  grid-row: 2 / 3;
}

.inscription .inscription-gauche {
  background: var(--bleu-principal);
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.inscription .inscription-gauche .logo-texte {
  width: 278px;
  max-width: 40vw;
}

.inscription .inscription-gauche .courbes {
  display: flex;
  flex-direction: row-reverse;
  background-color: #7a8efd;
  width: 30px;
  height: 100%;
  border-radius: 30px 0 0 30px / 50%;
}

.inscription .inscription-gauche .courbe-bleue {
  display: flex;
  flex-direction: row-reverse;
  background-color: #c6cfff;
  width: 20px;
  height: 100%;
  border-radius: 20px 0 0 20px / 50%;
}

.inscription .inscription-gauche .courbe-blanche {
  background-color: white;
  width: 10px;
  height: 100%;
  border-radius: 10px 0 0 10px / 50%;
}

.inscription .inscription-gauche .logo img {
  height: 100%;
  max-height: 90px;
}

.inscription .bloc-formulaire {
  letter-spacing: 0.2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.inscription .bloc-formulaire > p {
  text-align: center;
}

.inscription .bloc-formulaire h1,
.inscription .bloc-formulaire h2 {
  text-align: center;
}

.inscription .bloc-formulaire form > div {
  text-align: center;
}

.inscription .bloc-formulaire form > div input {
  box-sizing: border-box;
  width: 90%;
  max-width: 400px;
  padding: 13px;
  border: 2px solid #eee;
  border-radius: 29px;
  margin: 10px;
  text-align: center;
  background: #eef0ff;
  border: none;
  font-size: 1.2rem;
  color: var(--gris-formulaire);
}

.inscription .bloc-formulaire form > div input::placeholder {
  color: var(--gris-formulaire);
}

.inscription .bloc-formulaire form > div button {
  width: 100%;
  max-width: 220px;
  padding: 13px;
  border-radius: 29px;
  margin: 10px;
  background: var(--bleu-principal);
  border: 1px solid var(--bleu-principal);
  color: #ffffff;
  font-size: 1.2rem;
}

.inscription .bloc-formulaire form > div select {
  display: block;
  font-size: 1.2rem;
  color: rgb(75, 70, 70);
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
  margin: 0 auto 10px;
  border: 0;
  border-right: 19px solid #eef0ff;
  border-radius: 23px;
  background-color: #eef0ff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.inscription .bloc-formulaire form > div select::-ms-expand {
  display: none;
}

.inscription .bloc-formulaire form > div select:hover {
  border-color: #eef0ff;
  cursor: pointer;
}

.inscription .bloc-formulaire form > div select:focus {
  border-color: #eef0ff;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  color: #222;
  outline: var(--bleu-principal);
}

.inscription .bloc-formulaire form > div select option {
  font-weight: normal;
}

.inscription .bloc-formulaire form > div button:hover {
  cursor: pointer;
  border: 1px solid #000;
}

.inscription .bloc-formulaire form .aggree {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

@media all and (max-width: 500px), all and (max-height: 720px) {
  .inscription {
    grid-template-columns: 1fr;
  }
  .inscription .bloc-formulaire h1,
  .inscription .bloc-formulaire h2 {
    font-size: 14px;
  }
  .inscription .bloc-formulaire {
    margin: 0;
  }
  .inscription .inscription-gauche {
    display: none;
  }
  .inscription .bloc-formulaire {
    align-self: center;
    justify-self: center;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .inscription .bloc-formulaire form > div input {
    width: 90%;
    padding: 6px;
    border: 1px;
    border-radius: 15px;
    margin: 5px;
    font-size: 1rem;
  }

  .inscription .bloc-formulaire form > div select {
    font-size: 1rem;
    color: var(--gris-formulaire);
    padding: 0.5em 1.4em 0.5em 0.8em;
    width: 90%;
    margin: 5px auto;
  }
}
