.fichiers {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
}

.colonne-gauche {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: var(--bleu-principal);
  display: flex;
  flex-direction: column;
  width: 300px;
  border-top-right-radius: 10px;
}

.marge-bleue {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;
  background-color: var(--bleu-secondaire);
  width: 70px;
  border-top-right-radius: 10px;
}

.marge-droite {
  grid-column: 4/5;
  grid-row: 1/4;
  width: 105px;
}

.bouton-ajouts {
  grid-column: 3/4;
  grid-row: 2/3;
}

.bouton-ajouts a.bt-img-et-texte {
  margin: 17px;
  font-size: 13px;
}

.bouton-ajouts a.bt-img-et-texte .img {
  font-size: 22px;
}

@media (max-width: 1200px) {
  .marge-bleue {
    width: 0px;
  }

  .marge-droite {
    width: 0px;
  }
}

@media (min-width: 1000px) {
  .bouton-ajouts {
    display: none;
  }
}

@media (max-width: 1000px) {
  .colonne-gauche {
    display: none;
  }
}

.fichiers .corps {
  grid-column-start: 3;
  grid-column-end: 4;
  overflow-y: auto;
  grid-row-start: 3;
}

.element-menu {
  text-align: left;
  padding: 1em 1em 1em 2em;
}

.element-menu a {
  color: white;
  text-decoration: none;
}

.element-menu a:hover {
  text-decoration: underline;
}

.element-menu a img {
  padding: 0 10px 11px 0;
}

.explications_sur_hover {
  border-bottom: 1px dashed;
  text-decoration: none;
}

.fichiers .ajout {
  grid-column: 3/4;
  padding: 17px;
  color: var(--gris-formulaire);
}

.fichiers .ajout h1 {
  margin: 0;
  color: var(--bleu-principal);
}

.fichiers .ajout .formulaire {
  margin: 25px 0;
}

.fichiers .ajout .formulaire input {
  width: 100%;
  padding: 13px;
  border-radius: 29px;
  background: #eef0ff;
  border: none;
  font-size: 1.2rem;
  color: rgb(75, 70, 70);
}

.fichiers .ajout .mail,
.fichiers .ajout .permissions {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.fichiers .ajout .mail .groupe input {
  margin-right: 15px;
}

.fichiers .ajout .mail .choix,
.fichiers .ajout .permissions .choix {
  margin: 10px 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.fichiers .ajout .boutons {
  margin: 10px 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.deux-colonnes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.1em;
}

.fichiers .ajout .mail .deux-colonnes > div {
  border: 1px solid var(--bleu-secondaire);
  border-radius: 5px;
  padding: 0.1em 0.6em;
}
