.mot-de-passe-oublie {
  display: grid;
  grid-template-rows: 40vh 60vh;
}

.mot-de-passe-oublie .logo {
  background-color: var(--bleu-principal);
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

.mot-de-passe-oublie .logo .courbes {
  display: flex;
  flex-direction: column-reverse;
  background-color: #7a8efd;
  width: 100%;
  height: 30px;
  border-radius: 50%/30px 30px 0 0;
}

.mot-de-passe-oublie .logo .courbes .courbe-bleue {
  display: flex;
  flex-direction: column-reverse;
  background-color: #c6cfff;
  width: 100%;
  height: 20px;
  border-radius: 50%/20px 20px 0 0;
}

.mot-de-passe-oublie .logo .courbes .courbe-blanche {
  background-color: white;
  width: 100%;
  height: 10px;
  border-radius: 50%/10px 10px 0 0;
}

.mot-de-passe-oublie .logo img {
  margin-bottom: 30px;
  height: 100%;
  max-height: 90px;
  max-width: 96vw;
}

.mot-de-passe-oublie .corps {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.mot-de-passe-oublie .corps .centre {
  grid-column: 2/3;
  width: 100%;
  max-width: 96vw;
  text-align: center;
}

.mot-de-passe-oublie .corps h1 {
  color: var(--bleu-principal);
  font-size: 25px;
}

.mot-de-passe-oublie .corps p {
  color: var(--bleu-principal);
  font-size: 19px;
}

.mot-de-passe-oublie .corps form {
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: center;
  row-gap: 15px;
}

.mot-de-passe-oublie .corps form button {
  color: white;
  font-size: 19px;
  background-color: var(--bleu-principal);
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
}

.mot-de-passe-oublie .corps form input {
  color: var(--gris-formulaire);
  font-size: 19px;
  background-color: var(--bleu-formulaire);
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  width: 100%;
}

.mot-de-passe-oublie .corps form button:hover {
  cursor: pointer;
}

.mot-de-passe-oublie .corps .retour {
  color: var(--bleu-principal);
}

.mot-de-passe-oublie .erreur {
  border: 1px solid var(--erreur-police);
  border-radius: 5px;
}

.mot-de-passe-oublie .erreur h1 {
  color: var(--erreur-police);
  font-size: 18px;
}

.mot-de-passe-oublie .erreur ul li {
  font-size: 14px;
}
