html,
body {
  height: 100%;
}

body {
  overflow: hidden;
  font-family: "Lato Regular";
}

h1 {
  font-size: 1.5rem;
  color: #292929;
  font-weight: normal;
}

a:hover,
button:hover {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

table tr.impair,
.ligne.impair {
  background-color: var(--bleu-formulaire);
}

input[type="checkbox"]:hover,
input[type="radio"]:hover {
  cursor: pointer;
}

button {
  word-break: keep-all;
}

button:disabled:hover {
  cursor: not-allowed;
}

.root {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
}

.chargement {
  display: grid;
  justify-content: center;
  align-content: center;
}

.succes {
  color: var(--succes-police);
}

.echec {
  color: var(--erreur-police);
}

.center {
  text-align: center;
}

.flex-right {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin: 10px 0;
}

.emphase {
  font-weight: bold;
}

.img-ombre-flotte {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 400px) {
  h1 {
    font-size: 1em;
    margin: 0.3em 0;
  }
}
