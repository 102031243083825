.progres {
  height: 10px;
  padding: 3px;
  border: solid 1px var(--bleu-secondaire);
  border-radius: 5px;
}

.progres .barre {
  height: 100%;
}
