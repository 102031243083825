.chemin {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: center;
}

.fichiers .chemin {
  font-family: "Lato Light";
  font-size: 18px;
  margin-left: 17px;
}

.fichiers .chemin a {
  text-decoration: none;
}

.fichiers .chemin a:hover {
  text-decoration: underline;
}

.fichiers .chemin a,
.fichiers .chemin a:visited {
  color: var(--bleu-principal);
}

.fichiers .chemin .active {
  color: var(--noir-fil);
}
