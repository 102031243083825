.barre-action {
  background-color: var(--gris-separateur);
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto auto auto;
  grid-template-rows: 1fr;
  column-gap: 2px;
  row-gap: 2px;
  font-size: 14px;
}

.barre-action > div {
  background-color: #f0f0f0;
}

.barre-action > div.couverture {
  background-color: rgba(0, 0, 0, 0.7);
}

.barre-action > div > button {
  width: 48px;
  height: 48px;
  padding: 0;
}

.barre-action .description-tache {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.barre-action .description-tache button {
  font-size: 3em;
  font-style: italic;
  font-family: serif;
  line-height: 0px;
}

.barre-action .tache-rapide {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  padding: 0 10px;
  display: flex;
  align-content: center;
}

.barre-action .tache-rapide select {
  border: none;
  background: none;
  max-width: 300px;
}

.barre-action .tache-rapide select:hover {
  cursor: pointer;
}

.barre-action .contexte {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr auto;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.barre-action .contexte .action-en-cours {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.barre-action .contexte .action-en-cours p {
  padding: 0 5px;
  font-size: 12px;
}

.barre-action .contexte p {
  margin: 0;
  padding: 0 10px;
}

.barre-action .recherche-adresse {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

.barre-action .recherche-adresse .champ-recherche {
  width: 400px;
  height: 48px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.barre-action .recherche-adresse .champ-recherche input::placeholder {
  color: #181818;
  opacity: 1;
}

.barre-action .recherche-adresse input {
  width: 390px;
  padding: 8px 10px 8px 40px;
  border: 0;
}

.barre-action .recherche-adresse input:focus {
  outline: none;
}

.barre-action .recherche-adresse .liste-adresse {
  position: absolute;
  z-index: 3000;
  background-color: #f0f0f0;
  max-width: 400px;
}

.barre-action .recherche-adresse .liste-adresse .adresse {
  margin: 5px 6px;
  background-color: #dddddd;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #dddddd;
}

.barre-action .recherche-adresse .liste-adresse .adresse.active {
  border-color: var(--bleu-principal);
}

.barre-action .aide {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
}

.barre-action .aide button {
  font-size: 25px;
}

.barre-action .aide.active button {
  background-color: var(--bleu-principal);
  color: white;
}

.barre-action .plein-ecran {
  grid-column: 6 / 7;
  grid-row: 1 / 2;
}

.barre-action .plein-ecran button img {
  height: 23px;
}

.barre-action .geolocalisation {
  grid-column: 7 / 8;
  grid-row: 1 / 2;
}

.barre-action .geolocalisation button img {
  height: 32px;
}

.barre-action .zoom {
  display: flex;
  align-items: center;
  grid-column: 8 / 9;
  grid-row: 1 / 2;
}

.barre-action .zoom .bouton-gauche {
  border-right: 2px solid var(--gris-separateur);
}

.barre-action .zoom p {
  margin: 0 10px;
  width: 60px;
  text-align: center;
}

.barre-action .zoom button {
  border: none;
  font-size: 1.5rem;
  width: 40px;
}

@media all and (max-width: 1024px) {
  .barre-action {
    grid-template-columns: auto 1fr auto auto auto auto auto;
    grid-template-rows: 1fr 1fr;
  }

  .barre-action .contexte {
    grid-column: 1 / 8;
    grid-row: 2 / 3;
  }

  .barre-action .recherche-adresse {
    grid-column: 3 / 4;
  }

  .barre-action .recherche-adresse .champ-recherche {
    max-width: 345px;
    height: 32px;
    width: 100%;
  }

  .barre-action .recherche-adresse input {
    max-width: 341px;
    width: 98%;
    padding: 3px 10px 3px 30px;
    background-size: 30px;
  }

  .barre-action .aide {
    grid-column: 4 / 5;
  }

  .barre-action .plein-ecran {
    grid-column: 5 / 6;
  }

  .barre-action .geolocalisation {
    grid-column: 6 / 7;
  }

  .barre-action .zoom {
    grid-column: 7 / 8;
  }

  .barre-action > div > button {
    width: 32px;
    height: 32px;
  }

  .barre-action .description-tache button {
    font-size: 2em;
  }
}

@media all and (max-width: 512px) {
  .barre-action {
    grid-template-columns: auto 1fr auto auto auto auto;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .barre-action .remplissage {
    grid-column: 1 / 2;
    grid-column: 2 / 3;
  }

  .barre-action .tache-rapide {
    grid-column: 1 / 7;
    grid-row: 2 / 3;
  }

  .barre-action .contexte {
    grid-column: 1 / 7;
    grid-row: 3 / 4;
  }

  .barre-action .contexte p {
    font-size: 11px;
  }

  .barre-action .recherche-adresse {
    grid-column: 2 / 3;
  }

  .barre-action .recherche-adresse .champ-recherche {
    width: 100%;
  }

  .barre-action .recherche-adresse input {
    width: 98%;
  }

  .barre-action .aide {
    grid-column: 3 / 4;
  }

  .barre-action .plein-ecran {
    grid-column: 4 / 5;
  }

  .barre-action .geolocalisation {
    grid-column: 5 / 6;
  }

  .barre-action .zoom {
    grid-column: 6 / 7;
  }
}

@media all and (min-width: 512px) {
  .barre-action .remplissage {
    display: none;
  }
}

@media (hover: none) and (pointer: coarse) {
  .barre-action .zoom {
    display: none;
  }
}
