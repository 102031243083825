.arborescence ul {
  padding-left: 52px;
  list-style: none;
}

.arborescence ul.racine {
  padding-left: 0;
}

.arborescence ul li {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.arborescence ul.racine > li {
  padding: 0;
}

.arborescence ul.racine > li > ul {
  padding-left: 20px;
}

.arborescence ul.racine .racine {
  margin-right: 10px;
}

.arborescence ul li:before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 10px;
  height: 1px;
  margin: auto;
  content: "";
  background-color: #666;
}

.arborescence ul li .source {
  font-weight: bold;
}

.arborescence ul li .source i {
  font-weight: bold;
  color: var(--alerte-police);
}

.arborescence ul li .destination i {
  font-weight: bold;
  color: var(--succes-police);
}

.arborescence ul li.doc:before {
  top: 18px;
}

.arborescence ul.racine > li::before {
  background-color: transparent;
}

.arborescence ul li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: #666;
}

.arborescence ul.racine > li:after {
  background-color: transparent;
}

.arborescence ul li:last-child:after {
  height: 30px;
}

.arborescence ul li.doc:last-child:after {
  height: 18px;
}

.arborescence ul a {
  cursor: pointer;
}

.arborescence ul a:hover {
  text-decoration: none;
}
