.menu {
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
  align-items: center;
  height: 100%;
}

.menu .carre {
  height: 90px;
  width: 90px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.menu .logo img {
  height: 50px;
}

.menu .elem-profil .bt-profil {
  height: 50px;
  width: 50px;
}

.menu .elem-profil .sous-menu .bt-profil {
  height: 33px;
  width: 33px;
}

.bt-profil .bt-profil-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.bt-profil .bt-profil-grid .cercle,
.bt-profil .bt-profil-grid .lettres {
  grid-column: 1/2;
  grid-row: 1/2;
}

.bt-profil .bt-profil-grid .cercle:hover,
.bt-profil .bt-profil-grid .lettres:hover {
  cursor: pointer;
}

.bt-profil .bt-profil-grid .cercle {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: var(--bleu-principal);
}

.bt-profil .bt-profil-grid .lettres {
  font-size: 85%;
}

.bt-profil .bt-profil-grid .lettres svg {
  fill: #ffffff;
  width: 50px;
  height: 14px;
}

.menu .coeur {
  grid-column: 4 / 5;
}

.menu .coeur .bt-coeur {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  justify-content: center;
}

.menu .coeur .bt-coeur img {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  height: 50px;
}

.menu .coeur .bt-coeur .montant {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.menu .coeur .bt-coeur img {
  z-index: 100;
}

.menu .coeur .bt-coeur .montant {
  color: #ffffff;
  z-index: 200;
  text-align: center;
}

.menu .menu-3-items {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2em;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.menu .menu-3-items .menu-item.nav-separator {
  width: 1px;
  background-color: black;
  height: 34px;
}

.menu .menu-3-items .menu-item button {
  display: flex;
  align-items: center;
  column-gap: 1em;
  text-decoration: none;
  color: #292929;
  border-radius: 10px;
  padding: 10px;
  font-family: "Lato Light";
  font-size: 18px;
}

.menu .menu-3-items .menu-item.active button {
  color: var(--bleu-principal);
}

.menu .menu-3-items .menu-item button:hover {
  text-decoration: underline;
  color: var(--bleu-principal);
}

.menu .menu-3-items .menu-item button:hover img {
  opacity: 0.5;
}

.menu .menu-3-items .menu-item button img {
  justify-self: right;
  height: 37px;
}

.menu .menu-3-items .menu-item button span {
  justify-self: left;
}

.menu .croix {
  align-self: center;
  grid-column: 6 / 7;
}

.menu .croix .bt-croix {
  text-align: center;
  cursor: pointer;
}

.menu .croix .bt-croix:hover {
  border-radius: 5px;
  padding: 5px;
  border: 2px solid #7a8efd;
}

.menu .elem-profil {
  grid-column: 5 / 6;
}

.menu ul.sous-menu {
  position: fixed;
  right: 0;
  top: 90px;
  border-top: solid 1px #cccccc;
  z-index: 7000;
  background-color: #ffffff;
}

.menu ul.sous-menu li:first-child .mon-profil {
  border-bottom: solid 1px #cccccc;
}

.menu ul.sous-menu li button {
  display: grid;
  grid-template-columns: 2fr 8fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  height: 50px;
  width: 260px;
  background-color: #f6f6f6;
  color: var(--noir-fil);
  text-decoration: none;
  font-size: 1.1em;
  justify-items: center;
  border: none;
}

.menu ul.sous-menu li button span {
  justify-self: left;
  align-self: center;
}

.menu ul.sous-menu li button:hover {
  background-color: #ffffff;
  text-decoration: underline;
}

.menu ul.sous-menu li button img {
  height: 25px;
  justify-self: center;
}

.menu ul.sous-menu li.sous-menu-document button img {
  height: 19px;
}

.menu .connecteur {
  position: fixed;
  top: 80px;
  border-left: solid 10px transparent;
  border-bottom: solid 10px #ccc;
  border-right: solid 10px transparent;
  z-index: 7000;
}

.menu .croix .connecteur {
  right: 35px;
}

.menu .elem-profil .connecteur {
  right: 125px;
}

.menu .elem-profil .menu-profil .bt-profil {
  grid-row: 1 / 4;
}

.menu .elem-profil .menu-profil .bt-profil .bt-profil-grid .lettres svg {
  width: 30px;
}

.menu .elem-profil .menu-profil .bt-profil .circle {
  border-radius: 25px;
  background-color: var(--bleu-principal);
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.menu .elem-profil .menu-profil .bt-profil:hover > .circle {
  background-color: var(--bleu-principal);
}

.couverture-menu {
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 2750;
  grid-column: 1 / 7;
  grid-row: 1 / 2;
  height: 100%;
}

.menu .nom-projet {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-family: "Lato Light";
  font-size: 20px;
  color: var(--bleu-principal);
}

.menu ul.sous-menu.menu-croix li {
  border-bottom: solid 1px #cccccc;
}

@media all and (max-width: 1100px) {
  .menu .menu-3-items {
    display: none;
  }
}

@media all and (min-width: 1100px) {
  .petit {
    display: none;
  }
}

@media all and (max-width: 400px) {
  .menu .carre {
    height: 60px;
    width: 60px;
  }

  .menu .logo img {
    height: 33px;
  }

  .menu .coeur .bt-coeur img {
    height: 33px;
  }

  .menu .elem-profil .bt-profil .circle {
    border-radius: 16px;
    height: 33px;
    width: 33px;
  }

  .menu .elem-profil .bt-profil .text {
    font-size: 1em;
  }

  .menu .croix img {
    height: 26px;
  }

  .menu .elem-profil .bt-profil {
    height: 33px;
    width: 33px;
  }

  .bt-profil .bt-profil-grid .lettres svg {
    width: 32px;
    height: 14px;
  }

  .menu ul.sous-menu {
    top: 60px;
  }

  .menu .connecteur {
    top: 50px;
  }

  .menu .croix .connecteur {
    right: 20px;
  }

  .menu .elem-profil .connecteur {
    right: 80px;
  }
}
