.idees.selection-tache .leaflet-popup-content-wrapper {
  border: 1px solid #3049d7;
  overflow: hidden;
  border-radius: 8px;
  padding: 0;
}

.idees.selection-tache .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0;
}

.idees.selection-tache .leaflet-popup-tip-container {
}

.bulle-idee {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.bulle-idee .bulle-desc-idee {
  grid-column: 1 / 2;
  border-right: 1px solid var(--bleu-principal);
  padding: 8px 8px 8px 15px;
}

.bulle-idee .bulle-desc-idee .bulle-compteurs {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1px;
  background: var(--gris-separateur-fin);
}

.bulle-idee .bulle-desc-idee .bulle-compteurs span {
  background: #ffffff;
}

.bulle-idee .bulle-desc-idee .bulle-compteurs span.compteur-idees {
  padding-right: 10px;
}

.bulle-idee .bulle-desc-idee .bulle-compteurs span.compteur-contributeurs {
  padding-left: 10px;
  color: var(--gris-separateur);
}

.bulle-idee .bulle-boutons {
  grid-column: 2 / 3;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 1px;
  background: var(--bleu-principal);
}

.bulle-idee .bulle-boutons button {
  color: #ffffff;
  text-align: center;
  background: var(--bleu-secondaire);
  border: none;
}

.bulle-idee .bulle-boutons button:hover {
  background: var(--bleu-principal);
  opacity: 80%;
}

.bulle-idee .bulle-boutons button:active {
  opacity: 100%;
}
