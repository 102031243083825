.tuiles {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.tuiles a {
  text-decoration: none;
}

.tuile {
  margin: 10px;
  padding: 10px;
  font-size: 0.9em;
  border-radius: 10px;
  background-color: #f7f7f7;
  border: solid 4px #f7f7f7;
}

.tuile:hover {
  border-color: #3049d7;
  cursor: pointer;
}

.tuile .illustration {
  background-color: #f2f2f2;
  width: 290px;
  height: 290px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.tuile .illustration img {
  width: 100%;
  vertical-align: baseline;
  color: #5e5757;
}

.tuile h2 {
  max-width: 290px;
  text-decoration: none;
  color: #5e5757;
}

@media all and (max-width: 1024px) {
  .tuile h2 {
    max-width: 200px;
    font-size: 1em;
  }

  .tuiles .tuile .illustration {
    width: 200px;
    height: 200px;
  }
}
/* 
.tuile .tuile-tache {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.tuile .tuile-tache h2 {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  max-width: none;
}

.tuile .tuile-tache h3 {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.tuile .tuile-tache .illustration {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.tuile .tuile-tache .description-tache {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
} */
