.home {
  grid-row: 2 / 3;
}

.home-login {
  display: grid;
  grid-template-rows: 1fr auto;
}

.home-login .logo {
  background-color: var(--bleu-principal);
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

.home-login .logo .courbes {
  display: flex;
  flex-direction: column-reverse;
  background-color: #7a8efd;
  width: 100%;
  height: 30px;
  border-radius: 50%/30px 30px 0 0;
}

.home-login .logo .courbes .courbe-bleue {
  display: flex;
  flex-direction: column-reverse;
  background-color: #c6cfff;
  width: 100%;
  height: 20px;
  border-radius: 50%/20px 20px 0 0;
}

.home-login .logo .courbes .courbe-blanche {
  background-color: white;
  width: 100%;
  height: 10px;
  border-radius: 50%/10px 10px 0 0;
}

.home-login .logo img {
  margin-bottom: 30px;
  height: 100%;
  max-height: 90px;
  max-width: 96vw;
}

.home-login .corps {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.home-login .corps .centre {
  grid-column: 2/3;
  width: 100%;
  max-width: 96vw;
  text-align: center;
  min-height: 60vh;
}

.home-login .centre .pres {
  max-width: 800px;
  color: var(--bleu-principal);
}

.home-login .centre .pres h1 {
  font-size: 20px;
  font-family: "Lufga Regular";
  color: var(--bleu-principal);
}

.home-login .centre .pres p {
  font-size: 16px;
  font-family: "Lufga Extra Light";
}

.home-login .login input {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 13px;
  border: 2px solid #eee;
  border-radius: 29px;
  margin: 10px 0;
  text-align: center;
  background: var(--bleu-formulaire);
}

.home-login .login button {
  color: white;
  font-size: 19px;
  background-color: var(--bleu-principal);
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  margin: 7px 0;
}

.home-login .login button:hover,
.home-login .login button:active {
  color: #ffffff;
  background: #3049d7;
  cursor: pointer;
}

.home-login .login .mdp-oublie {
  margin: 10px 0 10px 0;
}

.home-login .login a {
  text-decoration: none;
  color: var(--bleu-principal);
  font-family: "Lato Light";
}

.home-login .login a:hover {
  text-decoration: underline;
}

.home-login .nom-app {
  text-align: left;
}

.home-login .nom-app > p {
  margin: 0;
}

.home-login .nom-app > p.nom-app-carto {
  margin: 0 0 0 -5px;
  font-family: "Lato Regular";
  font-weight: bold;
}

.home-login .erreur {
  border: 1px solid var(--erreur-police);
  border-radius: 5px;
  margin: 10px auto;
}

.home-login .erreur h1 {
  color: var(--erreur-police);
  font-size: 18px;
}

.home-login .erreur ul li {
  font-size: 14px;
}

.home-choix {
  display: grid;
  grid-template-rows: 38% 62%;
  justify-items: center;
  row-gap: 5vh;
  padding: 2%;
}

.home-choix h1 {
  font-size: 22px;
  color: #292929;
  align-self: end;
  font-family: "Lufga Regular";
}

.home-choix h1 span.bonjour {
  color: var(--bleu-principal);
}

.home-choix .elements {
  align-self: auto;
  width: 100%;
}

.home-choix .elements .flux {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.home-choix .element {
  width: 300px;
  height: 130px;
  display: grid;
  grid-template-columns: 35% 65%;
  border-radius: 10px;
  text-decoration: none;
  align-items: end;
  justify-items: center;
}

.home-choix .element.home-doc img {
  width: 80px;
}

.home-choix .element p {
  margin: 0;
  justify-self: left;
  font-size: 22px;
  color: #181818;
  font-family: "Lufga Light";
}

.home-choix .element:hover img {
  opacity: 0.5;
}

.home-choix .element:hover p {
  color: var(--bleu-principal);
}

.home-choix .element p .emphase {
  font-family: "Lato Regular";
}

@media all and (max-width: 500px), all and (max-height: 600px) {
  .home-login .corps {
    grid-template-columns: 1fr;
  }

  .home-login .corps .centre {
    grid-column: 1/2;
    justify-self: center;
  }
  .home-choix .element {
    width: 200px;
    height: 70px;
    padding: 0;
  }
  .home-choix .element img {
    height: 50px;
  }
  .home-choix .element.home-doc img {
    height: 36px;
  }
  .home-choix .element p {
    font-size: 1em;
    margin: 0;
  }

  .home-choix h1 {
    font-size: 1rem;
    margin: 0;
  }

  .home-login .centre .pres {
    display: none;
  }
}
