.bt-texte {
  font-size: 18px;
  padding: 4px 26px;
  border-radius: 18px;
  letter-spacing: 0.1rem;
  border: none;
}

a.bt-texte {
  text-decoration: none;
  padding: 2px 26px;
}

.bt-img {
  border: none;
}

.bt-img:hover {
  filter: brightness(85%);
}

.bt-img:active {
  filter: brightness(70%);
}

.bt-img-rond {
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 20px;
}

.bt-img-rond svg {
  fill: white;
  width: 16px;
  height: 17px;
}

.bt-bleu {
  background-color: var(--bleu-principal);
  color: #ffffff;
}

.bt-bleu:hover {
  background-color: var(--bleu-principal-hover);
}

.bt-bleu:active {
  background-color: var(--bleu-principal-active);
}

.bt-bleu:disabled {
  background-color: var(--bleu-principal-disabled);
  color: #a4a4a4;
}

.bt-bleu:disabled svg {
  fill: #a4a4a4;
}

.bt-gris {
  background-color: #a6a6a6;
  color: #ffffff;
}

.bt-gris:hover {
  background-color: #979797;
}

.bt-gris:active {
  background-color: #5b5b5b;
}

.bt-gris:disabled {
  background-color: #e6e6e6;
  color: #a4a4a4;
}

.bt-bleu-secondaire {
  background-color: var(--bleu-secondaire);
  color: #ffffff;
}

.bt-bleu-secondaire:hover {
  background-color: #6c81f8;
}

.bt-suppr {
  padding: 4px;
}

.bt-suppr svg {
  width: 22px;
  height: 22px;
}

.bt-dl {
  padding: 7px 4px 4px;
}

.bt-dl svg {
  width: 22px;
  height: 21px;
}

a.bt-img-et-texte {
  display: inline-block;
  padding: 0px 26px 0px 12px;
}

.bt-img-et-texte span {
  vertical-align: middle;
}

.bt-img-et-texte span.img {
  font-size: 30px;
  padding-bottom: 1px;
  padding-right: 10px;
}

.bt-lien {
  border: none;
  background-color: transparent;
}

.bt-fermer {
  border: none;
  background: transparent;
  border-radius: 12px;
  padding: 0;
}

.bt-fermer:hover {
  opacity: 0.8;
}

@media (max-width: 400px) {
  .bt-texte {
    font-size: 0.9em;
    padding: 4px 11px;
  }

  .bt-img-rond {
    height: 33px;
    width: 33px;
    border-radius: 17px;
  }

  .bt-img-rond svg {
    width: 15px;
    height: 15px;
  }

  .bt-suppr {
    padding: 3px;
  }

  .bt-suppr svg {
    width: 24px;
    height: 28px;
  }

  .bt-dl svg {
    width: 23px;
    height: 25px;
  }
}
