.infos-tache {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  align-self: flex-start;
  justify-self: left;
  max-height: 100%;
  width: 300px;
  min-height: 500px;
  z-index: 2500;
}

.infos-tache .corps-infos-tache {
  background-color: #f0f0f0;
  border: solid 1px #707070;
  padding: 10px;
  overflow-y: auto;
}

.infos-tache h1,
.infos-tache h2,
.infos-tache h3 {
  font-weight: normal;
}

.infos-tache h1 {
  font-size: 1.3em;
}

.infos-tache h2 {
  font-size: 1.2em;
}

.infos-tache h3 {
  font-size: 1.1em;
}

.infos-tache ul {
  padding-left: 40px;
}

.infos-tache ul li {
  list-style-type: disc;
}

.infos-tache ol li {
  list-style-type: decimal;
}
