.fichiers .corps .liste-fichiers {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

table th {
  background-color: var(--gris-header);
  font-family: "Lato Light";
  padding: 0.5em;
  text-align: left;
}

table td {
  padding: 0.7em;
  font-family: "Lato Light";
}

table img {
  padding-right: 1em;
  padding-left: 0.5em;
}

table {
  width: 100%;
}

table a {
  color: black;
  text-decoration: none;
  font-family: "Lato Light";
}

.fichiers .corps .liste-fichiers .entete {
  background-color: var(--gris-header);
  font-family: "Lato Light";
}

.fichiers .corps .liste-fichiers .entete > div,
.fichiers .corps .liste-fichiers .lignes .ligne > div {
  padding: 7px 17px;
}

.fichiers .corps .liste-fichiers .pied,
.fichiers .corps .liste-fichiers .entete {
  display: grid;
  grid-template-columns: 6fr 2fr 7fr 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
}

.fichiers .corps .liste-fichiers .entete .faux-scroll,
.fichiers .corps .liste-fichiers .pied .faux-scroll {
  grid-column: 5/6;
  grid-row: 1/2;
  overflow-y: scroll;
  padding: 0;
}

.fichiers .corps .liste-fichiers .lignes .ligne {
  display: grid;
  grid-template-columns: 6fr 2.5fr 7fr 1fr;
  grid-template-rows: auto auto auto;
  align-items: center;
}

.fichiers .corps .liste-fichiers .lignes {
  overflow-y: scroll;
  grid-column: 1/2;
  grid-row: 2/3;
}

.fichiers .corps .liste-fichiers .entete .nom,
.fichiers .corps .liste-fichiers .lignes .ligne .nom {
  grid-column: 1/2;
  grid-row: 1/2;
}

.fichiers .corps .liste-fichiers .entete .date,
.fichiers .corps .liste-fichiers .lignes .ligne .date {
  grid-column: 2/3;
  grid-row: 1/2;
}

.fichiers .corps .liste-fichiers .lignes .ligne .actions {
  grid-column: 3/4;
  grid-row: 1/2;
  display: flex;
  justify-self: right;
  gap: 7px;
}

@media (max-width: 700px) {
  .fichiers .corps .liste-fichiers .lignes .ligne {
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto auto auto;
  }

  .fichiers .corps .liste-fichiers .entete .date {
    display: none;
  }

  .fichiers .corps .liste-fichiers .lignes .ligne .date {
    grid-column: 1/2;
    grid-row: 2/3;
    padding-left: 42px;
  }
}

@media (max-width: 400px) {
  .fichiers .corps .liste-fichiers .lignes .ligne .actions {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}

.fichiers .corps .liste-fichiers .entete .selectionner,
.fichiers .corps .liste-fichiers .lignes .ligne .selectionner {
  grid-column: 4/5;
  grid-row: 1/2;
  justify-self: center;
}

.fichiers .corps .liste-fichiers .pied .boutons {
  grid-column: 4/5;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  justify-self: center;
}

.fichiers .corps .liste-fichiers .pied .boutons > div {
  padding: 5px;
}

.fichiers .corps .liste-fichiers .pied .boutons .telechargement {
  grid-column: 1/2;
  grid-row: 1/2;
}

.fichiers .corps .liste-fichiers .pied .boutons .supprimer {
  grid-column: 1/2;
  grid-row: 2/3;
}

.fichiers .corps .liste-fichiers {
  font-family: "Lato Light";
}

.fichiers .corps .liste-fichiers .ligne .nom a {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  text-decoration: none;
  color: #000000;
}

.fichiers .corps .liste-fichiers .ligne .nom a:hover {
  text-decoration: underline;
}

.fichiers .corps .liste-fichiers .ligne .nom a:visited {
  color: #000000;
}

.liste-fichiers .renommer div {
  margin: 10px 0;
}

.liste-fichiers .renommer input {
  border: #d8d8d8 1px solid;
  border-radius: 5px;
  padding: 6px;
  margin: 10px 0;
  width: 100%;
}

@media (max-width: 400px) {
  table th {
    writing-mode: sideways-lr;
    font-size: 0.9em;
  }

  table td {
    padding: 0.3em;
  }
}
