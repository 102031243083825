:root {
  --bleu-principal: #3049d7;
  --bleu-principal-hover: #253cc1;
  --bleu-principal-active: #1d2f96;
  --bleu-principal-disabled: #eaecfb;

  --bleu-secondaire: #7a8efd;
  --bleu-tertiaire: #c6cfff;
  --bleu-formulaire: #eef0ff;

  --gris-separateur: #979797;
  --gris-separateur-fin: #d8d8d8;

  --gris-hover: #969494;
  --gris-header: #fafafa;
  --gris-hover-bouton: #a6a6a6;
  --gris-formulaire: #100c50;
  --noir-leger: #292929;
  --noir-fil: #181818;
  --active: #ffffff;

  --erreur-fond: #fff6f6;
  --erreur-police: #9f3a38;
  --erreur-contour: #db2828;

  --succes-fond: #fcfff5;
  --succes-police: #2c662d;
  --succes-contour: #a3c293;

  --alerte-fond: #ffedde;
  --alerte-police: #f2711c;
  --alerte-contour: #f2711c;
}
