.switch * {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.switch *:focus {
  outline: none;
}

.switch {
  font-family: "Lato Regular";
}

.switch .knobs,
.switch .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  overflow: hidden;
}

.switch,
.switch .layer {
  border-radius: 100px;
}

.switch .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch .knobs {
  z-index: 2;
}

.switch .layer {
  width: 100%;
  background-color: #d8d8d8;
  transition: 0.3s ease all;
  z-index: 1;
}

.switch .knobs:before {
  content: "NON";
  position: absolute;
  top: 4px;
  left: 38px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #a6a6a6;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.switch .checkbox:checked + .knobs:before {
  content: "OUI";
  left: 4px;
  background-color: #3049d7;
}

.switch .checkbox:checked ~ .layer {
  background-color: #7a8efd;
}

.switch .knobs,
.switch .knobs:before,
.switch .layer {
  transition: 0.3s ease all;
}
